import React from 'react';
import { useNavigate } from "react-router-dom";
import logo from '../../assets/logo.svg';
import { useAuth } from "../../context/AuthProvider";
import { DEV_ADMIN_USERS } from "../../constant/index";

const Header = () => {
  const { logOut, userEmail, userName } = useAuth();
  const navigate = useNavigate();

  const onMenuSelection = (e) => {
    const selection = e.target.value;
    if (selection === 'logout') {
      logOut();
    } else if (selection === 'changePwd') {
      return navigate("/change-password");
    } else if (selection === 'updateProfile') {
      return navigate("/update-profile");
    } else if (selection === 'admin') {
      return navigate("/admin/ctdev");
    }
  }

  let displayName;
  if(userName) {
    displayName = userName;
  } else if(userEmail) {
    displayName = userEmail;
  }

  return (
    <div className="container">
      <nav className="navbar navbar-expand-lg bg-body-white">
        <div className="container-fluid">
          <a className="navbar-brand" href="/home">
            <img src={logo} alt="Capital Trends" />
          </a>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link active textColor" aria-current="page" href="/about-us">About</a>
              </li>

              {
                !displayName && (
                  <li className="nav-item dropdown">
                    <div className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Tools
                    </div>
                    <ul className="dropdown-menu">
                      <li><a className="dropdown-item" href="/marketDashboard">Market Dashboard</a></li>
                      <li><a className="dropdown-item" href="/patternRecognition">Pattern Recognition</a></li>
                      {/* <li><a className="dropdown-item" href="/tradeRecommendations">Trade Recommendations</a></li> */}
                    </ul>
                  </li>
                )
              }

              <li className="nav-item">
                <a className="nav-link active textColor" aria-current="page" href="/1-1-Coaching">1:1 Coaching</a>
              </li>

              <li className="nav-item">
                <a className="nav-link active textColor" aria-current="page" href="/contact-us">Contact</a>
              </li>
            </ul>
            {
              !userEmail && (
                <div className="d-flex">
                  <a className="btn btn-primary button-cus me-2" type="submit" href="/sign-up">Open a Demat Account</a>
                  <a className="btn btn-outline-primary me-2" type="submit" href="/sign-in">Login</a>
                  <a className="btn btn-primary button-cus" type="submit" href="/sign-up">Sign up</a>
                </div>
              )
            }

            {
              userEmail && (
                <div className="d-flex user_logged_in">
                  <div className="welcome_user">
                    Welcome {displayName}
                  </div>
                  <div className="header-right-container">
                    <form>
                      <select className="styled-select" name="userMenu" id="userMenu" onChange={onMenuSelection}>
                        <option value='0'>Please select</option>
                        <option value="updateProfile" >Update Profile</option>
                        <option value="changePwd">Change Password</option>
                        {
                          DEV_ADMIN_USERS.includes(userEmail) && (
                            <option value="admin">Admin Panel</option>
                          )
                        }
                        <option value="logout">Logout</option>
                      </select>
                    </form>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Header;