import React from "react";
import axios from "axios";
import { useAuth } from "../../../context/AuthProvider";
import { BASE_URL } from '../../../constant/index';

const DataLoad = () => {
    const { token } = useAuth();

    const callAPIProcedure = async () => {
        const response = await axios.post(BASE_URL+'/callPerfectAlignmentInverse', {
            tradingDate: '2024-12-18',
        } , {
            headers: {
                "Authorization": `Bearer ${token}`
            },

        });
        console.log(response.data);
    }

    return (
        <>
            <div className="container" style={{ height: '500px' }}>
                <button onClick={callAPIProcedure}>Perfect Alignment Inverse</button>
            </div>
        </>
    )

}

export default DataLoad;