import React, { useState } from "react";
import dayjs from 'dayjs';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useAuth } from "../../../context/AuthProvider";
import { 
    PATTERN_RECOGNITION_DAILY_PATTERNS, 
    PATTERN_RECOGNITION_WEEKLY_PATTERNS, 
    BASE_URL, 
    PATTERN_RECOGNITION_COLUMNS, 
    PATTERN_RECOGNITION_VISIBLE_COLUMNS,
    PATTERN_RECOGNITION_ADMIN_PATTERNS,
    ADMIN_USERS, 
} from '../../../constant/index';
import RenderTable from "./RenderTable";

const PatternRecognition = () => {
    const navigate = useNavigate();
	const { token, userEmail } = useAuth();
	const [ isLoading, setLoading ] = useState(false);
	const [ errorMsg, setErrorMsg ] = useState('');
    const [ pattern, setSelectedPattern ] = useState('52WH');
    const [ checkboxesState, setCheckboxes] = React.useState({
        checkboxAll: false,
        checkboxNse50: true,
        checkboxNse100: false,
    });
    const [ date, setSelectedDate ] = useState(dayjs());
    const [ patternData, setPatternData ] = useState([]);

    const getPatternRecognitionData = async () => {
		setLoading(true);
        const tradingDate = dayjs(date).format('YYYY-MM-DD');
        setPatternData([]);
		try {
			const response = await fetch(BASE_URL+'/getSymbolsMatchingPattern', {
				method: "POST",
                body: JSON.stringify({ tradingDate, patternCode: pattern }),
				headers: {
				  "Content-Type": "application/json",
				  "Authorization": `Bearer ${token}`
				},
			});

			const res = await response.json();
			if(res?.statusCode && res?.statusCode === 503) {
				setErrorMsg(res.msg);
			}
            if (res?.result && res?.result.length > 0) {
                setPatternData(res.result);
            }
			setLoading(false);
		} catch(err) {
			setErrorMsg('Server encountered an issue, please try later. If issue still persist, please reach out on help@capitaltrends.in');
			setLoading(false);
		}
	}

    React.useEffect(() => {
        const selectedDate = dayjs(date).toDate();
        const formattedDate = dayjs(selectedDate).format('YYYY-MM-DD');
		const params = { pattern, date: formattedDate };
		navigate({
			search: `?${createSearchParams(params)}`
		});
        getPatternRecognitionData();
	}, [pattern, date]);

    const columns = React.useMemo(
        () => PATTERN_RECOGNITION_COLUMNS.filter((column) => PATTERN_RECOGNITION_VISIBLE_COLUMNS.includes(column.field)),
        [],
    );
    
    const onPatternSelection = (value) => {
        setSelectedPattern(value);
        const params = { pattern: value, date };
		navigate({
			search: `?${createSearchParams(params)}`
		});
    }

    const onDateSelection = (value) => {
        const selectedDate = dayjs(value).toDate();
        const formattedDate = dayjs(selectedDate).format('YYYY-MM-DD');
        setSelectedDate(formattedDate);
        const params = { pattern, date: formattedDate };
		navigate({
			search: `?${createSearchParams(params)}` 
		});
    }

    const handleCheckboxes = (event) => {
        setCheckboxes({
            ...checkboxesState,
            [event.target.name]: event.target.checked,
        });
    };

    if (!userEmail) {
		return (
			<div className="container error-block">
				<div className="hr" />
				<div className="row">
					<div className="col-12">
						<h1 className="page-title-master">Pattern Recognition</h1>
					</div>

					<Stack sx={{ width: '100%' }} spacing={2}>
						<Alert severity="error">This is member-only section. Please login.</Alert>
					</Stack>
				</div>
			</div>
		)
	}

    const { checkboxAll, checkboxNse100 } = checkboxesState;

    return (
        <div className="container">
            <div className="row">
                <div className="col-3">
                    <div className="md-links-prs">
                        <h3 className="page-subtitle-master">Daily Pattern Links</h3>
                        {
                            ADMIN_USERS.includes(userEmail) && PATTERN_RECOGNITION_ADMIN_PATTERNS.map(({ key, value, label }) => (
                                <button key={key} onClick={() => onPatternSelection(value)} className={"btn " + (value === pattern ? 'btn-primary button-cus' : 'btn-outline-secondary')}>{label}</button>
                            ))
                        }
                        {
                            PATTERN_RECOGNITION_DAILY_PATTERNS.map(({ key, value, label }) => (
                                <button key={key} onClick={() => onPatternSelection(value)} className={"btn " + (value === pattern ? 'btn-primary button-cus' : 'btn-outline-secondary')}>{label}</button>
                            ))
                        }
                        <h3 className="page-subtitle-master">Weekly Pattern Links</h3>
                        {
                            PATTERN_RECOGNITION_WEEKLY_PATTERNS.map(({ key, value, label }) => (
                                <button key={key} onClick={() => onPatternSelection(value)} className={"btn " + (value === pattern ? 'btn-primary button-cus' : 'btn-outline-secondary')}>{label}</button>
                            ))
                        }
                    </div>
                </div>

                <div className="col-9">
                    <div className="row">
                        <div className="col-4">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker 
                                    label="Date"
                                    value={dayjs(date)}
                                    onChange={(newValue) => onDateSelection(newValue)}
                                    slotProps={{
                                        textField: {
                                          helperText: 'YYYY-MM-DD',
                                        },
                                    }}
                                    maxDate={dayjs()}
                                    format="YYYY-MM-DD"
                                />
                            </LocalizationProvider>    
                        </div>
                        <div className="col-5">
                            <Stack direction="row" spacing={2}>
                                <FormControlLabel control={<Checkbox checked={checkboxAll} onChange={handleCheckboxes} name="checkboxAll" />} label="All" />
                                <FormControlLabel control={<Checkbox checked={true} name="checkboxNse50" />} label="Nifty 50" />
                                <FormControlLabel control={<Checkbox checked={checkboxNse100 || checkboxAll} onChange={handleCheckboxes} name="checkboxNse100" />} label="Nifty 100" />
                            </Stack>
                        </div>
                        
                        <RenderTable errorMsg={errorMsg} isLoading={isLoading} patternData={patternData} columns={columns} checkboxesState={checkboxesState} />
                    </div>
                </div>
            </div>
        </div>       
    );
}   

export default PatternRecognition;