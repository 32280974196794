import React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Indexes from './Indexes';
import DataLoad from './DataLoad';

const CustomTabPanel = ({ children, value, index, ...other }) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`-tab-${index}`}
        {...other}
    >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
);

const Admin = () => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

  return (
    <div className="container py-5">
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="Admin Tabs">
                    <Tab label="Data Load" id="data-load" />
                    <Tab label="Indexes" id="indexes" />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <DataLoad />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Indexes />
            </CustomTabPanel>
        </Box>
    </div>
  );
};

export default Admin;
