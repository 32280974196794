import React from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Link, useLocation, useNavigate, Outlet } from 'react-router-dom';
import { useAuth } from "../../../../context/AuthProvider";
import { ADMIN_USERS } from '../../../../constant/index';

const Content = () => {
  const { userEmail } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.substring(1) || 'marketDashboard';
  const [value, setValue] = React.useState(path);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  React.useEffect(() => {
    if (!['marketDashboard', 'patternRecognition', 'futuresTrades'].includes(path)) {
      navigate('/marketDashboard');  // Default to dashboard if route is invalid
    }
    setValue(path);
  }, [path]);

  return (
    <div className="container py-5">
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Market Dashboard" value="marketDashboard" to="/marketDashboard" component={Link} />
            <Tab label="Pattern Recognition" value="patternRecognition" to="/patternRecognition" component={Link} />
            {
              ADMIN_USERS.includes(userEmail) && (
                <Tab label="Futures Trades" value="futuresTrades" to="/futuresTrades" component={Link} />
              )
            }
          </Tabs>
        </Box>
        <Box sx={{ padding: 2 }}>
          <Outlet />
        </Box>
      </Box>
    </div>
  );
}

export default Content;