import React, { useState } from "react";
import { useNavigate, createSearchParams } from 'react-router-dom';
import axios from "axios";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import RenderChart from "./RenderChart";
import { useAuth } from "../../../context/AuthProvider";
import { transformMdbData } from '../../../utils/transformMdbData';
import { MARKET_DASHBOARD_COMPONENTS, MARKET_DASHBOARD_DAYS, BASE_URL } from '../../../constant/index';
import { getMdbData } from "../../../utils/getMdbData";

dayjs.extend(utc);
dayjs.extend(timezone);

const IST = "Asia/Kolkata"; // Indian Standard Time

const MarketDashboard = ({ currentTab }) => {
	const navigate = useNavigate();
	const { token, userEmail } = useAuth();
	const [ isLoading, setLoading ] = useState(false);
	const [ errorMsg, setErrorMsg ] = useState('');
	const [ topLabels, setTopLabels ] = useState([]);
	const [ topData, setTopData ] = useState([]);
	const [ component, setSelectedComponents ] = useState('Nifty 50');
	const [ day, setSelectedDay ] = useState('1-Day');
	const [ arStockDataMaster, setArStockDataMaster ] = useState([]);
	const [ arMdbData, setArMdbData ] = useState([]);
	const [ chartCategory, setChartCategory ] = useState('top');

	const getMaxDate = async () => {
		setLoading(true);
		try {
			const response = await axios.get(BASE_URL+'/getMaxDate', {
				headers: {
					"Authorization": `Bearer ${token}`
				},
			});
			const maxDate = response.data;
			const cachedDate = sessionStorage.getItem('lastDataLoadDate');
			const maxDateObj = dayjs(maxDate).tz(IST);
        	const cachedDateObj = cachedDate ? dayjs(cachedDate).tz(IST) : null;

			if (cachedDate) {
				if (maxDateObj.isAfter(cachedDateObj)) {
					// Fresh data available, fetch and cache it
					await getMarketDashboardData();
				} else {
					// Use cached data if it's the latest
					await getMarketDashboardDataFromCache();
				}
			} else {
				// No cached dta, fetch fresh data
				await getMarketDashboardData();
			}
		} catch (e) {
			setErrorMsg('Server encountered an issue, please try later. If issue still persists, please reach out to help@capitaltrends.in');
		} finally {
			setLoading(false);
		}
	}

	const getMarketDashboardDataFromCache = async () => {
		const cachedData = sessionStorage.getItem('marketDashboardData');
		if(cachedData) {
			const res = JSON.parse(cachedData);
			const { arStockDataMaster, arStockMarketData, dateObj } = res;
			const getArMdbData = await transformMdbData(arStockMarketData, dateObj);
			const { topLabels, topData } = await getMdbData(arStockDataMaster, getArMdbData, component, day, chartCategory);
			setTopLabels(topLabels);
			setTopData(topData);
			setArStockDataMaster(arStockDataMaster);
			setArMdbData(getArMdbData);
			setLoading(false);
			return;
		} else {
			getMarketDashboardData();
		}
	}

	const getMarketDashboardData = async () => {
		setLoading(true);
		try {
			const response = await fetch(BASE_URL + '/getMarketDashboardData', {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${token}`
				},
			});
	
			const res = await response.json();
			if (res?.statusCode === 503) {
				setErrorMsg(res.msg);
				return;
			}

			if (res?.arStockDataMaster?.length > 0) {
				const { arStockDataMaster, arStockMarketData, dateObj } = res;
				const getArMdbData = await transformMdbData(arStockMarketData, dateObj);
				const { topLabels, topData } = await getMdbData(arStockDataMaster, getArMdbData, component, day, chartCategory);
				setTopLabels(topLabels);
				setTopData(topData);
				setArStockDataMaster(arStockDataMaster);
				setArMdbData(getArMdbData);

				// Cache the data and update the last load date
				const formattedDate = dayjs().tz(IST).format('YYYY-MM-DD');
				sessionStorage.setItem('marketDashboardData', JSON.stringify(res));
				sessionStorage.setItem('lastDataLoadDate', formattedDate);
			}
		} catch (err) {
			setErrorMsg('Server encountered an issue, please try later. If issue still persists, please reach out to help@capitaltrends.in');
		} finally {
			setLoading(false);
		}
	};
	
	React.useEffect(() => {
		getMaxDate();
	}, [token, BASE_URL]);
	
	React.useEffect(() => {
		const params = { component, day };
		navigate({
			search: `?${createSearchParams(params)}`
		});
	}, [component, day, currentTab]);

	const onComponentSelection = async (value) => {
		setTopLabels([]);
		setTopData([]);
		setSelectedComponents(value);
		const params = { component: value, day };
		const { topLabels, topData } = await getMdbData(arStockDataMaster, arMdbData, value, day, chartCategory);
		setTopLabels(topLabels);
		setTopData(topData);
		navigate({
			search: `?${createSearchParams(params)}`
		});
	}

	const onDaySelection = async (value) => {
		setTopLabels([]);
		setTopData([]);
		setSelectedDay(value);
		const params = { component, day: value };
		const { topLabels, topData } = await getMdbData(arStockDataMaster, arMdbData, component, value, chartCategory);
		setTopLabels(topLabels);
		setTopData(topData);
		navigate({
			search: `?${createSearchParams(params)}`
		});
	}

	const onCategoryChange = async (e) => {
		setChartCategory(e.target.value);
		const { topLabels, topData } = await getMdbData(arStockDataMaster, arMdbData, component, day, e.target.value);
		setTopLabels(topLabels);
		setTopData(topData);
	}

	if (!userEmail) {
		return (
			<div className="container error-block">
				<div className="hr" />
				<div className="row">
					<div className="col-12">
						<h1 className="page-title-master">Market Dashboard</h1>
					</div>

					<Stack sx={{ width: '100%' }} spacing={2}>
						<Alert severity="error">This is member-only section. Please login.</Alert>
					</Stack>
				</div>
			</div>
		)
	}

	return (
		<div className="container">
			<div className="row">
				<div className="col-4">
					<div className="md-links" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 0.6fr)', gap: '2px' }}>
						{MARKET_DASHBOARD_COMPONENTS.map(({ key, value, label }) => (
							<button 
								key={key} 
								onClick={() => onComponentSelection(value)} 
								className={`btn mdb-button-width ${value === component ? 'btn-primary button-cus' : 'btn-outline-secondary'}`}
							>
								{label}
							</button>
						))}
					</div>
				</div>
				<div className="col-8">
					<div className="days-links">
						{
							MARKET_DASHBOARD_DAYS.map(({ key, value, label }) => (
								<button key={key} className={"btn " + (value === day ? 'btn-primary button-cus' : 'btn-outline-secondary')} onClick={() => onDaySelection(value)}>{label}</button>
							))
						}
					</div>
					<div style={{ 'paddingTop': '10px' }}>
						<RadioGroup
							row
							aria-labelledby="row-radio-buttons-group-label"
							name="row-radio-buttons-group"
							onChange={onCategoryChange}
							value={chartCategory}
						>
							<FormControlLabel value="top" control={<Radio disabled={component === 'Sectoral Indices' || component === 'Thematic Indices'} />} label="Top 5" />
							<FormControlLabel value="bottom" control={<Radio disabled={component === 'Sectoral Indices' || component === 'Thematic Indices'} />} label="Bottom 5" />
						</RadioGroup>
					</div>

					<RenderChart 
						errorMsg={errorMsg}
						isLoading={isLoading} 
						topLabels={topLabels} 
						topData={topData} 
						day={day} 
						component={component} 
					/>	
				</div>
			</div>
		</div>        
	)
}

export default MarketDashboard;